import Vue from 'vue';
import VueRouter from 'vue-router';
import { Message } from 'element-ui';

Vue.use(VueRouter);

const routes = [
  { path: '*', redirect: '/' },
  {
    path: '/',
    name: 'HomeLink',
    component: () => import('@/views/Home/home.vue'),
    children: [
      // 金融产品
      {
        path: '/financialproducts',
        name: 'FinancialProductsLink',
        component: () =>
          import('@/views/FinancialProducts/financialproducts.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '金融产品', url: '' },
        ],
      },
      // 金融产品  详情
      {
        path: '/productsdetail',
        name: 'ProductsDetailLink',
        component: () => import('@/views/ProductsDetail/productsdetail.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '金融产品', url: '/financialproducts' },
          { name: '产品详情', url: '' },
        ],
      },
      // 金融机构
      {
        path: '/financialinstitutions',
        name: 'FinancialInstitutionsLink',
        component: () =>
          import('@/views/FinancialInstitutions/financialinstitutions.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '金融机构', url: '' },
        ],
      },
      // 金融机构  详情
      {
        path: '/agencydetails',
        name: 'AgencyDetailsLink',
        component: () => import('@/views/AgencyDetails/agencydetails.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '金融机构', url: '/financialinstitutions' },
          { name: '金融详情', url: '' },
        ],
      },
      // 融资信息
      {
        path: '/financeinformation',
        name: 'FinanceInformationLink',
        component: () =>
          import('@/views/FinanceInformation/financeinformation.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '融资信息', url: '' },
        ],
      },
      // 融资信息  详情
      {
        path: '/informationdetail',
        name: 'InformationDetailLink',
        component: () =>
          import('@/views/InformationDetail/informationdetail.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '融资信息', url: '/financeinformation' },
          { name: '详情', url: '' },
        ],
      },
      // 通知公告
      {
        path: '/noticeannounce',
        name: 'NoticeAnnounceLink',
        component: () => import('@/views/NoticeAnnounce/noticeannounce.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '通知公告', url: '' },
        ],
      },
      // 通知公告 详情
      {
        path: '/noticedetails',
        name: 'NoticeDetailsLink',
        component: () => import('@/views/NoticeDetails/noticedetails.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '通知公告', url: '/noticeannounce' },
          { name: '详情', url: '' },
        ],
      },
      // 政策发布
      {
        path: '/policyissued',
        name: 'PolicyIssuedLink',
        component: () => import('@/views/PolicyIssued/policyissued.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '政策发布', url: '' },
        ],
      },
      // 政策发布  详情
      {
        path: '/policydetails',
        name: 'PolicyDetailsLink',
        component: () => import('@/views/PolicyDetails/policydetails.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '政策发布', url: '/policyissued' },
          { name: '详情', url: '' },
        ],
      },
      // 用户说明
      {
        path: '/userexplain',
        name: 'UserExplainLink',
        component: () => import('@/views/UserExplain/userexplain.vue'),
        meta: [
          { name: '首页', url: '/' },
          { name: '用户说明', url: '' },
        ],
      },
      // 融资需求
      {
        path: '/financingneeds',
        name: 'FinancingNeedsLink',
        component: () => import('@/views/FinancingNeeds/financingneeds.vue'),
      },
      // 产品申请
      {
        path: '/webproductapply',
        name: 'WebProductApplyLink',
        component: () => import('@/views/WebProductApply/webproductapply.vue'),
        meta: [{ name: '首页', url: '/' }],
      },
    ],
  },
  // 登录
  {
    path: '/login',
    name: 'LoginLink',
    component: () => import('@/views/Login/login.vue'),
  },

  // 注册
  {
    path: '/resigter',
    name: 'Resigterink',
    component: () => import('@/views/Resigter/resigter.vue'),
  },
  // 忘记密码
  {
    path: '/forgetpass',
    name: 'ForgetPassink',
    component: () => import('@/views/ForgetPass/forgetpass.vue'),
  },
  // 个人中心
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: () => import('@/views/PersonalCenter/PersonalCenter.vue'),
    children: [
      {
        path: '/demandList',
        name: 'demandList',
        component: () => import('@/views/demandList/demandList.vue'),
      },
      {
        path: '/enterprisecertification',
        name: 'Enterprisecertification',
        component: () =>
          import('@/views/Enterprisecertification/Enterprisecertification.vue'),
      },
      {
        path: '/productList',
        name: 'productList',
        component: () => import('@/views/productList/productList.vue'),
      },
      {
        path: '/InstitutionalCenter',
        name: 'InstitutionalCenter',
        component: () =>
          import('@/views/InstitutionalCenter/InstitutionalCenter.vue'),
      },
      {
        path: '/getDemand',
        name: 'getDemand',
        component: () => import('@/views/getDemand/getDemand.vue'),
      },
      {
        path: '/productApplication',
        name: 'productApplication',
        component: () =>
          import('@/views/productApplication/productApplication.vue'),
      },
      {
        path: '/information',
        name: 'information',
        component: () => import('@/views/information/information.vue'),
      },
      {
        path: '/management',
        name: 'management',
        component: () => import('@/views/management/management.vue'),
      },
      {
        path: '/pool',
        name: 'pool',
        component: () => import('@/views/pool/pool.vue'),
      },
      // {
      //   path: '/FirmMessage',
      //   name: 'FirmMessage',
      //   component: () => import('@/views/FirmMessage/FirmMessage.vue'),
      // },
    ],
  },
];
console.log(routes);
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});
// const router = new VueRouter({
//    mode:'hash',
//    routes
//  })

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.hash.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// router.beforeEach(function(to, from, next) {
//   const isLogin = localStorage.eleToken;
//   const validate = ['/financialproducts', '/webproductapply'];
//   console.log(to, from);
//   if (!isLogin && validate.includes(to.path)) {
//     Message.error('请登录');
//     next('/login');
//     // next({ path: '/' });
//   }
//   next();
// });

export default router;
