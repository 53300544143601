import axios from 'axios';
import router from '../router';
import { Message } from 'element-ui';
import Vue from 'vue';

// 请求拦截
axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    if (
      response.data.status == 401 ||
      response.data.status == 402 ||
      response.data.status == 403 ||
      response.data.status == 405
    ) {
      Message({
        showClose: true,
        type: 'error',
        duration: 2000,
        message: response.data.msg,
        onClose: function() {
          tgf;
          localStorage.clear();
          setTimeout(() => {
            // router.replace('/login')
            // location.href = 'http://192.168.0.101:8889/sso/login?service=http://192.168.0.111:8015/#/'
            const vue = new Vue();
            vue.$$jump(location.href);
          }, 1000);
        },
      });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
