<template>
	<div class="rigfloat">
		<div class="rigfloat_phone">
			<el-tooltip class="item" effect="dark" content="电话：0971—96868" placement="left">
				<i class="el-icon-phone rigfloat_icon"></i>
            </el-tooltip>
			
		</div>
		<div class="rigfloat_email">
			<el-tooltip class="item" effect="dark" content="邮箱：qh96868@126.com" placement="left">
				<i class="el-icon-s-order rigfloat_icon"></i>
            </el-tooltip>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		}
	}
</script>

<style scoped>
	@import './rigfloat.css';
</style>