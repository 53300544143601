import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import axios from "./http";
import Mint from "mint-ui";
import "mint-ui/lib/style.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueCropper from "vue-cropper";

Vue.use(Mint);
Vue.use(ElementUI);
Vue.use(VueCropper);

Vue.config.productionTip = false;

// Vue.prototype.baseUrl = 'http://rzx.smeqh.cn/v1';
Vue.prototype.baseUrl = "http://zk.zhenchangkj.com/v1";

Vue.prototype.$$jump = (url) => {
  const isDev = true; //开发环境
  // const isDev = false; // 正式环境
  if (isDev) {
    location.href =
      "http://sso.zhenchangkj.com:9000/sso/auth?redirect=" +
      encodeURIComponent(url) +
      "?back=" +
      encodeURIComponent(url) +
      "&origin=" +
      2;
  } else {
    location.href =
      "http://sso.smeqh.cn:9000/sso/auth?redirect=" +
      encodeURIComponent(url) +
      "?back=" +
      encodeURIComponent(url) +
      "&origin=" +
      2;
  }
};

Vue.prototype.lastTime = 0;

axios.defaults.timeout = 30000;

// Vue.prototype.bus = new Vue
//get请求
Vue.prototype.$get = function(api, data) {
  if (data === undefined) {
    data = {};
  }

  let ox = axios({
    baseURL: this.baseUrl,
    url: api,
    method: "get",
    params: data,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return ox;
};

//post请求
Vue.prototype.$post = function(api, data) {
  if (data === undefined) {
    data = {};
  }

  let ox = axios({
    baseURL: this.baseUrl,
    url: api,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return ox;
};

//节流
Vue.prototype.$throttle = function(callback) {
  let nowTime = new Date();

  if (nowTime - this.lastTime > 1500) {
    callback();

    this.lastTime = nowTime;
  }
  console.log(nowTime - this.lastTime);
};

//转换时间戳
Vue.prototype.$setTime = function(item) {
  return item < 10 ? "0" + item : item;
};

Vue.prototype.$getTime = function(timestamp) {
  // timestamp = timestamp.toString().substring(0,10)
  timestamp = timestamp + "";
  timestamp = timestamp.substring(0, 10);
  let date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let h = date.getHours();
  let m = date.getMinutes();
  return `${year}-${this.$setTime(month)}-${this.$setTime(day)}`;
};

Vue.prototype.$turnTime = function(dateData) {
  let date = new Date(dateData);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  const time = y + "-" + m + "-" + d;

  return time;
};

//手机号验证
Vue.prototype.$regPhone = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

//邮箱验证
Vue.prototype.$regEmail = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
// /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/

//密码验证
Vue.prototype.$regPass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;

//使用  this.$regPhone.test(xxx)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
