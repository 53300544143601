<template>
	<div id="app">
		<div class="wrapper">
			<router-view />

			<app-rigfloat />
			 <!-- <loading></loading>  -->
		</div>
	</div>
</template>

<script>
	import RigFloat from '@/components/RigFloat/rigfloat'
	// import Loading from '@components/Loading/loading'

	export default {
		data() {
			return {}
		},
		components: {
			'app-rigfloat': RigFloat,
			// 'loading':Loading
		}
	}
</script>

<style>
	@import './assets/css/inital.css';
</style>
